import { rest } from 'msw';
import { store } from '../../index';
import { formatInstructionsAsRaw } from '../labs/data';
import { sec599V1FilesResponse, sec599V2FilesResponse } from '../../stories/mocks/sec599.stories';
import { sec560V1FilesResponse, sec560V2FilesResponse } from '../../stories/mocks/sec560.stories';
import AppConfiguration from '../../config/appConfig';
import { generatedLabMocks } from '../labs/courses/generateLabMocks';

const { sec560: sec560Mocks, sec599: sec599Mocks } = generatedLabMocks;
const config = AppConfiguration();

const { baseUrl } = config.services.mylabsService;

const mainHandlers = [
    rest.get(`${baseUrl}/account_details`, async (req, res, ctx) => {
        const originalResponse = await ctx.fetch(req);
        const originalResponseData = await originalResponse.json();

        return res(
            ctx.json(originalResponseData)
        );
    }),
    rest.get(`${baseUrl}/users`, async (req, res, ctx) => {
        const originalResponse = await ctx.fetch(req);
        const originalResponseData = await originalResponse.json();
        const updated = { ...originalResponseData };

        const user = store.getState().accountReducer.sansEmail;
        const {
            REACT_APP_MYLABS_FEATURE_TEST: limitedTesters,
            REACT_APP_MYLABS_FEATURE_TEST_DEV: fullTesters,
        } = process.env;

        if (!fullTesters.split(',')
            .concat(limitedTesters.split(','))
            .includes(user.split('@')[0])
        ) {
            return res(
                ctx.json(originalResponseData)
            );
        }

        const { a: sec560A, b: sec560B } = sec560Mocks.live[80755];
        const { a: sec599 } = sec599Mocks.live[79170];

        updated.data = updated.data.concat([
            formatInstructionsAsRaw(sec560A), formatInstructionsAsRaw(sec560B),
            formatInstructionsAsRaw(sec599),
        ]);
        return res(
            ctx.json(updated)
        );
    }),
    rest.post('http://localhost:8200/api/files', async (req, res, ctx) => {
        const originalResponse = await ctx.fetch(req);
        const originalResponseData = await originalResponse.json();
        const updated = { ...originalResponseData };

        const user = store.getState().accountReducer.sansEmail;
        const {
            REACT_APP_MYLABS_FEATURE_TEST: limitedTesters,
            REACT_APP_MYLABS_FEATURE_TEST_DEV: fullTesters,
        } = process.env;

        if (!fullTesters.split(',')
            .concat(limitedTesters.split(','))
            .includes(user.split('@')[0])
        ) {
            return res(
                ctx.json(originalResponseData)
            );
        }
        // Determine which files implementation we're using
        const { version } = store.getState().configReducer.config.features.filesApiProxy.getStudentFiles;

        let patchedFileData = {}; // this might be an object or an array

        if (version === 1) {
            // If we're using V1, we'll need to check which files are
            // being requested, and respond according to search param
            if (req.url.searchParams.get('course') === 'SEC599') {
                patchedFileData = {
                    ...patchedFileData,
                    ...sec599V1FilesResponse,
                };
            } else if (req.url.searchParams.get('course') === 'SEC560') {
                patchedFileData = {
                    ...patchedFileData,
                    ...sec560V1FilesResponse,
                };
            }
        } else if (version === 2) {
            // If we're using v2, we don't need to worry about search params
            const reqPayload = await req.json();

            patchedFileData = {
                ...sec599V2FilesResponse(
                    reqPayload[sec599Mocks.live[79170].a.testExtras.courseCatalog][0]
                ),
                ...sec560V2FilesResponse(
                    reqPayload[sec560Mocks.live[80755].a.testExtras.courseCatalog][0]
                ),
            };
        }

        return res(
            ctx.json({ ...updated, ...patchedFileData })
        );
    }),
];

export const browserHandlers = mainHandlers;
