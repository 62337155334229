/* eslint-disable react/jsx-curly-spacing */
import './dynamic-renderer.scss';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { FileDownloader } from '../autodownloader/autodownloader';
import FontAwesome from 'react-fontawesome';
import {
    fetchStudentMyLabsFile,
    getURL,
    sortByScope,
} from '../../utils/mylabs-files-api';
import PropTypes from 'prop-types';
/* eslint-disable valid-jsdoc, max-len, no-console */
/**
 * @typedef {{
 *      eventProductID: number|null,
 *      labID: number|null,
 *      labName: string,
 *      instance: string,
 *      metadata: import('../../../../types/typedefs').StudentFileMetadata[]
 * }} FileMetadata
 */

/**
 * @typedef {{
 *      studentId: string,
 *      files: import('../../../../types/typedefs').LabFiles
 *  }} DynamicContainerProps
 */

/**
 * Requests file metadata and renders DynamicRenderers
 * and the AttachmentRenderer as necessary.
 *
 * @param {DynamicContainerProps} props
 * @returns {JSX.Element|null}
 */
export const DynamicContainer = ({ studentId, files }) => {
    /**
     * @typedef {import('../../../../types/typedefs').GroupedFileMetadata} groupedFileMetadata
     * @type {[groupedFileMetadata, Function]}
     */
    const [groupedFileMetadata, setGroupedFileMetadata] = useState({
        inline: [],
        attachments: [],
    });

    /**
     * Takes response data from the files API and
     * determines whether it is inline or a simple
     * attachment.
     *
     * @param {import('../../../../types/typedefs').LabFiles} filesMetadataResponse
     */
    const handleStudentFileMetadata = (filesMetadataResponse) => {
        let filesMetadata = [];

        if (filesMetadataResponse.metadata) {
            filesMetadata = [...filesMetadataResponse.metadata];
        }
        /**
         * @type {import('../../../../types/typedefs').GroupedFileMetadata}
         */
        const groupedStudentFileMetadata = sortByScope(filesMetadata).reduce(
            (accumulator, responseFileMetaData) => {
                if (!responseFileMetaData) {
                    return accumulator;
                }

                if (responseFileMetaData['content-disposition'] === 'inline') {
                    accumulator.inline.push(responseFileMetaData);
                } else {
                    accumulator.attachments.push(responseFileMetaData);
                }

                return accumulator;
            },
            {
                inline: [],
                attachments: [],
            }
        );

        setGroupedFileMetadata(groupedStudentFileMetadata);
    };

    useEffect(() => {
        if (studentId) {
            handleStudentFileMetadata(files);
        }
    }, [studentId]);

    // We only want to render this component if there are associated files
    if (
        groupedFileMetadata.attachments.length === 0 &&
        groupedFileMetadata.inline.length === 0
    ) {
        return null;
    }

    return (
        <div className="dynamic-container">
            {groupedFileMetadata.inline.map((file) => (
                <DynamicRenderer
                    key={file.id}
                    fileId={file.id}
                    filename={file.filename}
                    description={file.description}
                    contentType={file['content-type']}
                    contentDisposition={file['content-disposition']}
                    courseId={file.courseID}
                    instanceId={file.instanceID}
                    studentId={file.studentID}
                    token={file.token}
                    noExpand={true}
                />
            ))}
            <AttachmentRenderer
                attachments={sortByScope(
                    groupedFileMetadata.attachments.concat(
                        groupedFileMetadata.inline
                    )
                )}
            />
        </div>
    );
};

DynamicContainer.propTypes = {
    /** The student for which the DynamicContainer is pulling content for */
    studentId: PropTypes.string,
    /** The student's active range for the lab */
    range: PropTypes.object,
    /** An array of student file metadata */
    files: PropTypes.object,
};

/**
 * Renders a panel from which files can be downloaded
 *
 * @param {{attachments: import('../../../../types/typedefs').StudentFileMetadata[]}} props
 */
export const AttachmentRenderer = ({ attachments }) => {
    /**
     * @type {[import('../../../../types/typedefs').AttachmentRendererDownloadableData, Function]}
     */
    const [downloadableData, setDownloadableData] = useState({
        blob: null,
        filename: null,
    });

    /**
     * Fetches file data for the given attachment,
     * setting the resulting datablob and filename to
     * the component state
     * @param {string} id - The file's id
     * @param {string} token - The token to download the file
     * @param {string} filename - The filename including extension
     * @param {string} courseID - The courseID
     * @param {string} [instanceID] - The course instance id
     * @param {string} [studentID] - The student's id
     */
    const downloadAttachment = async (
        id,
        token,
        filename,
        courseID,
        instanceID,
        studentID
    ) => {
        try {
            const url =
                getURL(courseID, instanceID, studentID) +
                `files/${id}?token=${token}`;
            const dataResponse = await fetch(url, {
                method: 'GET',
            });
            const dataBlob = await dataResponse.blob();

            setDownloadableData({
                blob: dataBlob,
                filename,
            });
        } catch (e) {
            console.error(`MYLABS_ERROR downloadAttachment ${e.message}`);
        }
    };

    return (
        <div>
            <p>
                <strong>Downloadable Files</strong>
            </p>
            <table className="attachment-renderer">
                <tbody>
                    {attachments &&
                        attachments.map((attachment) => {
                            const {
                                id,
                                filename,
                                token,
                                studentID,
                                instanceID,
                                courseID,
                                description,
                                scope,
                            } = attachment;
                            const scopeMap = {
                                course: 'Course',
                                instance: 'Event',
                                student: 'Personal',
                            };
                            const contentType = attachment['content-type'];

                            return (
                                <tr key={id}>
                                    <td className="attachment-renderer-field">
                                        {filename}
                                    </td>
                                    <td className="attachment-renderer-field">
                                        {description || 'No Description'}
                                    </td>
                                    <td className="attachment-renderer-field">
                                        {contentType}
                                    </td>
                                    <td className="attachment-renderer-field">
                                        {scopeMap[scope]}
                                    </td>
                                    <td className="attachment-renderer-field">
                                        <FontAwesome
                                            className="attachment-renderer-btn"
                                            name="download"
                                            onClick={async () =>
                                                await downloadAttachment(
                                                    id,
                                                    token,
                                                    filename,
                                                    courseID,
                                                    instanceID,
                                                    studentID
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>

            {downloadableData.blob && (
                <FileDownloader
                    fileBlob={downloadableData.blob}
                    cb={() => {
                        setDownloadableData({ blob: null, filename: null });
                    }}
                    downloadName={downloadableData.filename}
                />
            )}
        </div>
    );
};

AttachmentRenderer.propTypes = {
    /** An array of file attachments to render */
    attachments: PropTypes.array,
};

/**
 * Render dynamic content. Should be used as the child of a DynamicContainer
 *
 * @param {object} props
 *
 * @param {string} props.fileId - The id of the file
 *
 * @param {string} props.contentType - The file's content-type
 *
 * @param {string} props.contentDisposition - inline if the file is rendered, attachment if it only appears in the download renderer
 *
 * @param {string} props.filename - The filename including exension
 *
 * @param {string} props.token - The file's token
 *
 * @param {string} props.studentId - The id of the student currently logged in
 *
 * @param {string} props.instanceId - The course instance id
 *
 * @param {string} props.courseId - The id of the course
 *
 * @param {string} props.description - A description of the file from the API
 *
 * @param {boolean} [props.defaultExpanded] - Whether or not the content is expanded by default-- Applies to inline files
 *
 * @param {boolean} props.noExpand - True if the file content cannot be expanded or collapsed
 *
 * @returns
 */
export const DynamicRenderer = ({
    fileId,
    contentType,
    contentDisposition,
    filename,
    token,
    studentId,
    instanceId,
    courseId,
    description,
    defaultExpanded,
    noExpand,
}) => {
    /**
     * @typedef {boolean} rendererExpanded - True if the renderer is expanded
     * @type {[rendererExpanded, Function]}
     */
    const [rendererExpanded, setRendererExpanded] = useState(
        defaultExpanded || false
    );

    /**
     * @typedef {boolean} dataFetched - True if the associated file has been fetched
     * @type {[dataFetched, Function]}
     */
    const [dataFetched, setDataFetched] = useState(false);

    /**
     * @description
     * @type {[boolean, Function]}
     */
    const [initialized, setInitialized] = useState(false);

    /**
     * @type {[{type:string, data: object}, Function]}
     */
    const [fetchedDynamicData, setFetchedDynamicData] = useState({
        type: null,
        data: null,
    });

    /**
     * @typedef {string|null} dynamicRendererError - An error that has occured in the renderer
     * @type {[dynamicRendererError, Function]} -
     */
    const [dynamicRendererError, setDynamicRendererError] = useState(null);

    /**
     * Creates a downloadable data blob and url,
     * setting both to `fetchedDynamicData`
     * @param {*} dataResponse
     */
    const createDownload = async (dataResponse) => {
        try {
            const dataBlob = await dataResponse.blob();
            const blobUrl = URL.createObjectURL(dataBlob);

            setFetchedDynamicData({
                type: 'blob',
                data: {
                    blob: dataBlob,
                    url: blobUrl,
                },
            });
        } catch (e) {
            console.error(`MYLABS_ERROR createDownload ${e.message}`);
            throw e;
        }
    };

    /**
     * Creates a data blob and downloadable URL for an image
     * @param {*} dataResponse
     */
    const createImageURL = async (dataResponse) => {
        try {
            const imageBlob = await dataResponse.blob();
            const imageURL = URL.createObjectURL(imageBlob);

            setFetchedDynamicData({
                type: 'image',
                data: { blob: imageBlob, url: imageURL },
            });
        } catch (e) {
            console.error(e);
            throw e;
        }
    };
    /**
     * Returns metadata about the content-type of the provided headers
     * @param {string} responseContentType
     * @returns an object with keys containing the full, type, and subtype parts of the provided response header's Content-Type
     */
    const getResponseType = (responseContentType) => {
        const regex = /^(\w+)\/(\w+)/;
        const match = responseContentType.match(regex);

        if (match && match[0]) {
            return { full: match[0], type: match[1], subtype: match[2] };
        }
        return null;
    };

    const handleDynamicData = async (dataResponse) => {
        try {
            if (dataResponse.status >= 200 && dataResponse.status <= 300) {
                // if it's an attachment, just download it outright
                // this will be implemented when the API supports it
                if (contentDisposition === 'attachment') {
                    createDownload(dataResponse);
                    return;
                }
                const contentMimeType = getResponseType(contentType);

                switch (contentMimeType.type) {
                    case 'text': {
                        if (!['plain', 'markdown'].includes(contentMimeType?.subtype)) {
                            setDynamicRendererError(
                                'Error: Unsupported content type.'
                            );
                            // break;
                            return;
                        }
                        // We can further customize what happens with these types by their subtype
                        // We may need to do this if we want to prompt downloads for resources
                        // which are not marked attachments in Content-Disposition
                        //
                        // if (responseMimeType.subtype === "csv"){
                        //     createDownload(dataResponse);
                        //     break;
                        // }
                        if (contentMimeType.subtype === 'markdown') {
                            setFetchedDynamicData({
                                type: 'markdown',
                                data: await dataResponse.text(),
                            });
                            break;
                        }

                        setFetchedDynamicData({
                            type: 'text',
                            data: await dataResponse.text(),
                        });
                        break;
                    }

                    case 'application': {
                        createDownload(dataResponse);
                        break;
                    }

                    case 'image': {
                        createImageURL(dataResponse);
                        break;
                    }

                    default:
                        console.log('No valid response recieved.');
                        break;
                }
            } else {
                setDynamicRendererError(
                    'Error occured while retreiving file: ' +
                        dataResponse.statusText
                );
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    useEffect(() => {
        const getStudentFile = async () => {
            try {
                if ((rendererExpanded && !dataFetched) || noExpand) {
                    handleDynamicData(
                        await fetchStudentMyLabsFile({
                            courseId,
                            instanceId,
                            studentId,
                            fileId,
                            token,
                        })
                    );
                    setDataFetched(true);
                }
            } catch (e) {
                console.error(e);
                throw e;
            }
        };

        getStudentFile();
        setInitialized(true);
    }, [rendererExpanded]);

    /**
     * Return appropriate markup to wrap the fetched
     * Dynamic data according to its type (ie, text,
     * markdown, blob, image, or 'other')
     * @returns
     */
    const renderDynamicData = () => {
        switch (fetchedDynamicData.type) {
            case 'text': {
                return <p>{fetchedDynamicData.data}</p>;
            }

            case 'markdown': {
                return <ReactMarkdown>{fetchedDynamicData.data}</ReactMarkdown>;
            }

            case 'blob': {
                return (
                    <div>
                        <span>{filename} </span>
                        <span>{description || <em>No Descrption </em>}</span>
                        <span>
                            <a
                                href={fetchedDynamicData.data.url}
                                download={filename && filename}
                            >
                                Download
                            </a>
                        </span>
                    </div>
                );
            }

            case 'image': {
                return (
                    <img
                        src={fetchedDynamicData.data.url}
                        alt={description || ''}
                    />
                );
            }

            default: {
                return (
                    <p>
                        Unable to render this dynamic data. It could be still be
                        loading or something may have gone wrong.
                    </p>
                );
            }
        }
    };
    // If we have any error state, render the error message

    if (dynamicRendererError) {
        return <p>{dynamicRendererError}</p>;
    }

    // Return null until we initialize
    if (!initialized) {
        return null;
    }

    return (
        <div>
            {noExpand ? (
                renderDynamicData()
            ) : (
                <React.Fragment>
                    <button
                        onClick={() => setRendererExpanded(!rendererExpanded)}
                    >
                        {rendererExpanded ? '-' : '+'} {filename}
                    </button>
                    <div
                        className={
                            rendererExpanded ? 'dynamic-renderer-content' : ''
                        }
                    >
                        {rendererExpanded && renderDynamicData()}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};
DynamicRenderer.propTypes = {
    /** The id of the file */
    fileId: PropTypes.string,
    /** The file's content-type */
    contentType: PropTypes.string,
    /** The file's content-disposition */
    contentDisposition: PropTypes.string,
    /** The name of the file */
    filename: PropTypes.string,
    /** The short lived download token to access the file */
    token: PropTypes.string,
    /** The ID of the student/user the file relates to */
    studentId: PropTypes.string,
    /** The course instance ID the file is associated with */
    instanceId: PropTypes.string,
    /** The Id of the course the file is associated with */
    courseId: PropTypes.string,
    /** A description of the file */
    description: PropTypes.string,
    /** If content-disposition is inline */
    defaultExpanded: PropTypes.bool,
    /** Whether or not expansion is complete disabled */
    noExpand: PropTypes.bool,
};
