import { createSlice } from '@reduxjs/toolkit';

const INITIAL_CONFIG_STATE = {
    config: {},
};

export const configSlice = createSlice({
    name: 'config',
    initialState: INITIAL_CONFIG_STATE,
    reducers: {
        setConfig: (state, action) => {
            const { payload } = action;

            state.config = payload;
        },
    },
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
