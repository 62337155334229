/* eslint-disable operator-linebreak */
/**
 * This module contains mock data for incoming files from the mylabs files api
 */

/**
 * Create test file metadata.
 * @param {Partial<import("../../types/types").LabProduct>} lab
 * @param {{
 *      studentID?: string,
 *      fileName?: string,
 *      description?: string,
 *      contentType?: 'text'|'markdown'|'ovpn'|'pem'
 *      instanceOverride?: null|string
 *     scope?: 'course'|'instance'|'student'
 * }} [options]
 * @returns {import("../../types/typedefs").StudentFileMetadata}
 */
export const TestFileMetadata = (
    lab,
    {
        studentID = '123456',
        description = '',
        contentType = 'text',
        fileName = undefined,
        instanceOverride = undefined,
        scope = 'student',
    } = {}
) => {
    const fileVars = {
        extension: 'txt',
        type: 'text/plain',
    };

    switch (contentType) {
        case 'text':
            fileVars.extension = 'txt';
            fileVars.type = 'text/plain';
            break;
        case 'markdown':
            fileVars.extension = 'md';
            fileVars.type = 'text/markdown';
            break;
        case 'ovpn':
            fileVars.extension = 'ovpn';
            fileVars.type = 'application/ovpn';
            break;
        case 'pem':
            fileVars.extension = 'pem';
            fileVars.type = 'application/x-pem-file';
            break;
        default:
            break;
    }

    return {
        id: 'test-file-id',
        filename: fileName
            ? `${fileName.toLowerCase()}.${fileVars.extension}`
            : `${lab.name.toLowerCase()}.${fileVars.extension}`,
        description,
        'content-type': fileVars.type,
        'content-length': 10,
        'content-disposition': 'attachment',
        scope,
        courseID: lab.testExtras.courseCatalog.toLowerCase(),
        instanceID: instanceOverride
            ? instanceOverride
            : lab.event_product_id?.toString() || lab.name.toLowerCase(),
        studentID,
        token: 'dev',
    };
};

/**
 * @param {{
 *      id: number,
 *      event_product_id: number,
 *      name: string,
 * }} testLab
 */
export const TestFileRequestPayload = (testLab) => {
    const {
        event_product_id: eventProductID,
        name: labName,
        id: labID,
    } = testLab;
    const instance = eventProductID === null ? labName : eventProductID;

    return {
        type: 'file',
        eventProductID,
        instance,
        labID,
        labName,
    };
};

/**
 * @param {{
 *      id: number,
 *      event_product_id: number,
 *      mylabs_part: string,
 * }} testRange
 */
export const TestRangeFileRequestPayload = (testRange) => {
    const { mylabs_part: labName, id } = testRange;

    return {
        type: 'range',
        productCatalog: labName.slice(0, 6),
        instance: `jit-${id}`,
        rangeID: id,
        labName,
    };
};
