import { createSlice } from '@reduxjs/toolkit';
/**
 * @typedef {object} RangeProvisioningState
 * @property {import('../types/typedefs').RangeRule[]} courses
 * @property {import('../types/typedefs').RangeInstance[]} ranges
 */

/**
 * @type {RangeProvisioningState}
 */
const INITIAL_RANGE_PROVISIONING_STATE = {
    courses: [],
    ranges: [],
};

export const rangeProvisioningSlice = createSlice({
    name: 'rangeProvisioning',
    initialState: INITIAL_RANGE_PROVISIONING_STATE,
    reducers: {
        /**
         * @param {RangeProvisioningState} state
         * @param {{payload: {
         *      courses: {
         *          data: import('../types/typedefs').RangeRule[]
         *      }
         *      ranges: {
         *      data: import('../types/typedefs').RangeInstance[]
         *      }
         * }}} action
         */
        setRangeData: (state, action) => {
            const { courses, ranges } = action.payload;

            state.courses = courses.data;
            state.ranges = ranges.data;
        },
    },
});

export const { setRangeData } = rangeProvisioningSlice.actions;

export default rangeProvisioningSlice.reducer;
