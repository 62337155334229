import React, { useState } from 'react';
import PropTypes from 'prop-types';
const LicensingUsageConfirmation = ({
    children,
    confirmationText,
    cb,
    firesOnce,
}) => {
    const [confirmUsage, setConfirmUsage] = useState(false);
    const [callbackFired, setCallbackFired] = useState(false);

    const fireCallback = () => {
        if (!firesOnce && callbackFired) {
            return;
        }

        setCallbackFired(true);
        cb();
    };

    return (
        <section>
            <label htmlFor="licensing-usage-confirmation__checkbox">
                {' '}
                <input
                    name="licensing-usage-confirmation__checkbox"
                    className="licensing-usage-confirmation__checkbox"
                    type="checkbox"
                    onClick={ () => {
                        const use = !confirmUsage;

                        setConfirmUsage(use);
                        use && cb && fireCallback();
                    } }
                />
                {confirmationText}
            </label>
            {confirmUsage && children}
        </section>
    );
};

LicensingUsageConfirmation.propTypes = {
    children: PropTypes.object,
    /** The text shown next to the checkbox */
    confirmationText: PropTypes.string,
    /** A callback to be fired when the user clicks the checkbox */
    cb: PropTypes.func,
    /** Does the callback fire once (true) or multiple times (false)? */
    firesOnce: PropTypes.bool,
};

export default LicensingUsageConfirmation;
