import { createSlice } from '@reduxjs/toolkit';
/**
 * @typedef {object} AccountSliceState
 * @property {string|null} sansAccountID
 * @property {string|null} sansEmail
 * @property {string|null} name
 */

/**
 * @type {AccountSliceState}
 */
const INITIAL_ACCOUNT_STATE = {
    sansAccountID: null,
    sansEmail: null,
    name: null,
};

export const accountSlice = createSlice({
    name: 'account',
    initialState: INITIAL_ACCOUNT_STATE,
    reducers: {
        /**
         *
         * @param {AccountSliceState} state
         * @param {{
         *      payload: {
         *          sansAccountID: string,
         *          sansEmail: string,
         *          name: string
         *      },
         *      type: string
         *  }} action
         */

        setAccountDetails: (state, action) => {
            const {
                payload: {
                    sansAccountID,
                    sansEmail,
                    name,
                },
            } = action;

            state.sansAccountID = sansAccountID;
            state.sansEmail = sansEmail;
            state.name = name;
        },
    },
});

export const { setAccountDetails } = accountSlice.actions;

export default accountSlice.reducer;
