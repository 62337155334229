import { getFlashpointLicense, getMaltegoLicense } from '../../utils/api-licensing';
import CloudProviders from '../cloud-providers/cloud-providers';
import AMIForm from './ami/ami';
import Arsenal from './arsenal/arsenal';
import CellebriteForm from './cellebrite/cellebrite';
import Corellium from './corellium/corellium';
import IdaPro from './ida/idapro';
import PushbuttonLicense from './pushbutton-license/PushbuttonLicense';
import Shodan from './shodan/shodan';

/**
 * An object mapping product licenses to their relevant forms
 */
const licenseForms = {
    'Cellebrite Tools': { component: CellebriteForm },
    'Amazon Machine Images': { component: AMIForm },
    'Arsenal Tools': { component: Arsenal },
    'Corellium Platform Access': { component: Corellium },
    'Shodan Tools': { component: Shodan },
    'IDA Pro': { component: IdaPro },
    'IDA Pro Tools': { component: IdaPro },
    'Cloud Training Environment': { component: CloudProviders },
    'Maltego Tools': {
        component: PushbuttonLicense,
        licensingIntegrationFn: getMaltegoLicense,
        content: {
            buttonText: 'Request Maltego License',
        },
    },
    'Flashpoint Tools': {
        component: PushbuttonLicense,
        licensingIntegrationFn: getFlashpointLicense,
        content: {
            buttonText: 'Request Flashpoint License',
            successText: 'Thank you for requesting your license. ' +
                'You will receive an email directly from Flashpoint with your license key. ' +
                'Please allow for up to 48 hours to hear from Flashpoint.',
        },
    },
};

// If we need to exclude existing license fields (except AMI), add them to the
// the array below as they appear above, ie:
// export const licensingExclude = ['Cellebrite Tools'];
export const licensingExclude = [];

export default licenseForms;
