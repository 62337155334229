import { handledGetFetch } from '../submodules/shared/utils/api-base';
import { getAccountDetails } from '../submodules/shared/utils/api-shared';
import { API_BASE } from '../constants';

const getLabInstructionsByUser = () => handledGetFetch(`${ API_BASE }/users`);

export {
    handledGetFetch, //Needed for lab-display submodule
    getLabInstructionsByUser,
    getAccountDetails, //shared
};
