/**
 * NOTE: THIS MODULE IS DEPRECATED.
 */
/* eslint-disable */

import '../license-form.scss';
import React, { useState } from 'react';
import { Formik, ErrorMessage, Form, Field } from 'formik';
import * as yup from 'yup';
import { getAMI } from '../../../utils/api-licensing';
import LicenseDisplayWithChildren from '../license-display/license-display-with-children';
import LicenseError from '../license-error/license-error';
import LicensingUsageConfirmation from '../licensing-usage-confirmation/licensing-usage-confirmation';
const amazonAcctRegex = /(^\d{12}|(?:\d{4}-?){3})/;

let amiFormSchema = yup.object().shape({
    'aws-act-id': yup.mixed().test({
        name: 'account-id',
        params: { amazonAcctRegex },
        message:
            'Input must be a valid AWS Account ID, such as 123456789012 or XXXX-XXXX-XXXX',
        test: (value) => {
            // We can possibly end up checking against undefined.
            // Return null, so yup doesn't choke on empty inputs.
            const match = value ? value.match(amazonAcctRegex) : null;
            if (match !== null) {
                // The user can still input more digits than allowed, so ensure we have the correct amt
                // const valueNoHyphens = value.replaceAll('-', '');
                const valueNoHyphens = value.replace(/-/g, '');
                if (valueNoHyphens.length !== 12) {
                    return false;
                }
                // This will also accept patterns like: 1234-56789012 and 12345678-9012
                return true;
            }
            return false;
        },
    }),
});

/**
 *
 * @param {*} labName
 * @returns
 */
export const getLabCatalogAndVersion = (labName) => {
    if (!labName) return null;
    const match = labName.match(
        /(?<courseName>[A-Z0-9]+)-(?<courseVersion>[A-Z0-9]+)/i
    );
    const { courseName, courseVersion } = match.groups;
    return {
        courseName: courseName.toUpperCase(),
        courseVersion: courseVersion.toLowerCase(),
    };
};

/**
 * @deprecated in favor of feature added in XHYD-194 (see docs)
 */
export const AMIField = ({ userAMI }) => {
    return (
        <div className="ami">
            <p className="ami-id">{userAMI.label}</p>
            <p className="ami-instructions">
                Region(s): {`${userAMI.regions.join(', ')}`}
            </p>
            <a target="_blank" rel="noopener noreferrer" href={userAMI.url}>
                {userAMI.url}
            </a>
        </div>
    );
};

/**
 * @deprecated in favor of feature added in XHYD-194 (see docs)
 */
export const NewUserAMI = ({ userAMI }) => {
    return (
        <p>
            AMI shared with account #xxxx-{userAMI.awsAccountId.slice(-4)} in{' '}
            {`${userAMI.regions.join(', ')}`}. Please log into AWS, select one
            of those regions, and launch the template located here:{' '}
            <a target="_blank" rel="noopener noreferrer" href={userAMI.url}>
                {userAMI.url}
            </a>
            .
        </p>
    );
};
/**
 * Renders an AMI Form
 * @deprecated in favor of feature added in XHYD-194 (see docs)
 */
const AMIForm = ({ lab }) => {
    const [AMIAquisitionError, setAMIAquisitionError] = useState(null);
    const [gotExistingUserAMIs, setGotExistingUserAMIs] = useState(false);
    const [userAMIs, setUserAMIS] = useState([]);
    const [newUserAMI, setNewUserAMI] = useState(null);

    const fetchUserAMIs = async ({ course, awsAccountId, key }) => {
        const userAMIData = await getAMI({ course, key, awsAccountId });
        return userAMIData;
    };

    const getExistingUserAMIs = async () => {
        try {
            if (!gotExistingUserAMIs){
                const amiRequestData = { course: lab.name, key: lab.key };
                const existingUserAMIs = await fetchUserAMIs(amiRequestData);
                setUserAMIS(existingUserAMIs.amis);
            }
        } catch (e) {
            console.log(e);
            setAMIAquisitionError(' ');
        } finally {
            setGotExistingUserAMIs(true);
        }
    };

    return (
        <section>
            <LicensingUsageConfirmation
                confirmationText={'I need to use the cloud VM.'}
                cb={!gotExistingUserAMIs && getExistingUserAMIs}>
                {userAMIs.length > 0 && (
                    <div id="user-amis">
                        <h5>Current User AMIs</h5>
                        <LicenseDisplayWithChildren>
                            {userAMIs.map((userAMI) => (
                                <AMIField
                                    userAMI={userAMI}
                                    key={userAMI.label}
                                />
                            ))}
                        </LicenseDisplayWithChildren>
                    </div>
                )}

                <Formik
                    initialValues={{ 'aws-act-id': '' }}
                    onSubmit={(values, { setSubmitting }) => {
                        const submitForm = async () => {
                            try {
                                setAMIAquisitionError(null);
                                const amiRequestData = {
                                    course: lab.name,
                                    awsAccountId: values['aws-act-id'].replace(
                                        /-/g,
                                        ''
                                    ),
                                    /**
                                     * NOTE: This key is an extremely temporary fixture, it should be removed by the next release
                                     * It should be removed once backend services utilize mylabs student claims jwt - @JedaiAtSans
                                     *  */
                                    key: 'c1c46e53-dd12-48a8-8e47-7b280d978a93',
                                };
                                const amiData = await fetchUserAMIs(
                                    amiRequestData
                                );
                                setNewUserAMI(amiData.amis);
                            } catch (e) {
                                setAMIAquisitionError(
                                    'An error occured while attempting to retrieve your Amazon Machine Image. ',
                                    e.message && e.message
                                );
                            } finally {
                                setSubmitting(false);
                            }
                        };
                        submitForm();
                    }}
                    validationSchema={amiFormSchema}>
                    {({ errors, isSubmitting, values }) => (
                        // We can reuse the license-* styling for this
                        <Form id="ami-form" className="license-form">
                            <div>
                                <p>
                                    <strong>
                                        Acquire a new Amazon Machine Image
                                    </strong>
                                </p>
                                <p>
                                    To acquire your AMI, please input your
                                    12-digit AWS Account ID and submit the form
                                    below. (Dashes are not required)
                                </p>
                                <div className="ami-form-field-container">
                                    <label htmlFor="aws-act-id">
                                        AWS Account ID
                                    </label>
                                    <Field
                                        id="aws-act-id"
                                        name="aws-act-id"
                                        type="text"
                                        placeholder="Enter your AWS Account ID"
                                    />
                                </div>
                                    <ErrorMessage
                                        className="license-error"
                                        name="aws-act-id"
                                        component="div"
                                    />
                                {!isSubmitting && (
                                    <button
                                        type="submit"
                                        disabled={
                                            isSubmitting ||
                                            errors['aws-act-id'] ||
                                            values['aws-act-id'].length === 0
                                        }>
                                        Submit AWS Account ID
                                    </button>
                                )}
                                {isSubmitting && (
                                    <div className="download-spinner" />
                                )}
                            </div>

                            {AMIAquisitionError && (
                                <LicenseError
                                    error={AMIAquisitionError}
                                    altResourceName="Amazon Machine Image"
                                />
                            )}
                            {newUserAMI && (
                                <LicenseDisplayWithChildren>
                                    {newUserAMI.map((ami) => (
                                        <NewUserAMI
                                            key={ami.label}
                                            userAMI={ami}
                                        />
                                    ))}
                                </LicenseDisplayWithChildren>
                            )}
                        </Form>
                    )}
                </Formik>
            </LicensingUsageConfirmation>
        </section>
    );
};

export default AMIForm;
