import axios from 'axios';
import { API_BASE } from '../constants-base';

/**
 * Returns a human-readable string for provisioning a range.
 *
 * @param {int} val timer counter
 * @returns string
 */
export const calculateDisplayInterval = (val) => {
    if (val / 60 < 1) {
        return `${val} seconds`;
    }
    return `${val / 60} minute${ (val / 60) > 1 ? 's' : ''}`;
};

// eslint-disable-next-line valid-jsdoc
/**
 * Compare the current time with a range expiration to determine whether
 *  a user is allowed to request a new one, yet.
 * @param {import('../../../types/typedefs').RangeInstance} range
 */
export const checkRangeExpiration = (range) => {
    if (range === null) {
        return -1;
    }
    const extensionCutoff = 15; //minutes
    const startDate = new Date(range.expires_at);
    const currentDate = new Date();

    const msUntilExtension = Math.abs(startDate.getTime() - currentDate.getTime());

    // convert to mins, ensure it is greater than 15
    return Math.floor(msUntilExtension / 1000 / 60) > extensionCutoff;
};

/**
 * Make a request to create a new range
 * @param {string} productVersion - the course lab version (ie, ABC123-A01[-01])
 * @param {string} rangeType - the range type
 * @param {string} awsRegion - the AWS region to provision within
 */
export const instantiateRange = async (productVersion, rangeType, awsRegion) => {
    const payload = {
        productVersion,
        rangeType,
        awsRegion,
    };

    try {
        const response = await axios.post(
            `${API_BASE}/range_provisioning`,
            payload,
            {
                withCredentials: true,
            },
        );

        const { result, data } = response.data;

        if (result !== 'success') {
            throw new Error(`failed to instantiate range via DRP API: ${result}`);
        }

        return data;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.message);
    }

    return null;
};

export const isRangeProvisioned = async (range) => {
    /**
     * Until we can get accurate reporting back from Odin when a range's
     * resources are _fully_ provisioned, we utilize a combination of the following:
     * 1. The range instance record in the DRP API must have a "fulfilled" status
     * 2. The stopwatch covering the estimated provisioning time must have expired
     *
     * For context, we've tried pinging the Files API endpoint to determine
     * when credentials get published. This gets us closer to where we want
     * to be, although it's not perfect, since network connectivity plus
     * credentials will be the driver toward student access. However, sadly,
     * A vs. B range types create problems since the Files API expects
     * the actual course name, not course + A or B, e.g., "SEC565" vs.
     * "SEC565A".
     *
     * That is the reason for using the combination of two items above, in
     * that we don't care what the actual course name identifiers are; we
     * simply use the range instance identifier instead.
     *
     * 2023-10-25 MEH
     */

    /* eslint-disable */
    // The following tries to get provisioning status from the files API:
    // try {
    //     const metadata = await fetchStudentFiles(
    //         range.product_catalog.substring(0, range.product_catalog.indexOf('-')),
    //         [],
    //         range.id
    //     );

    //     return metadata.data['jit-' + range.id].length > 0;
    // } catch (e) {
    //     // eslint-disable-next-line no-console
    //     console.log(e.message);
    //     return false;
    // }

    /* eslint-disable */
    /**
     * The following may work in the future, when Odin reports status to
     * the backing SMM API.
     */
    try {
        const response = await axios.get(
            `${API_BASE}/range_provisioning/status?id=${range.id}`,
            { withCredentials: true },
        );
        const { data: { status }} = response.data;

        return status === 'fulfilled';
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e.message);
        return false;
    }
};

/**
 * Extend an existing range by its ID
 * @param {number} rangeId - The ID of the range to extend
 * @param {string} expiresAt - The current expiration date of the range to extend
 * @returns {Promise<import ('../../../types/typedefs').RangeInstance>}
 */
export const extendRange = async (rangeId, expiresAt) => {
    try {
        const response = await axios.get(
            `${API_BASE}/range_provisioning/extend`,
            {
                params: {
                    id: rangeId,
                    expires_at: expiresAt,
                },
                withCredentials: true,
            },
        );

        const { result, data } = response.data;

        if (result !== 'success') {
            throw new Error(`failed to extend range via DRP API: ${result}`);
        } else if (typeof data === 'undefined') {
            throw new Error('failed to extend range via DRP API: range expired');
        }

        return data;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.message);
    }
};

/**
 * Delete an existing range by its ID
 * @param {number} rangeId - The ID of the range to extend
 * @returns {Promise<boolean>}
 */
export const deleteRange = async (rangeId) => {
    try {
        const response = await axios.delete(
            `${API_BASE}/range_provisioning/${rangeId}`,
            { withCredentials: true },
        );

        if (response.status !== 200) {
            throw new Error(`failed to delete range via DRP API: ${response.status} status code received`);
        }

        return true;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.message);
    }

    return false;
}

// eslint-disable-next-line valid-jsdoc
/**
 * Provides the inputs necessary for the range instantiation button rendered
 * to students.
 *
 * @param {object} lab lab containing lab info and an array of instructions
 * @param {array} rules Reducer data for range rules
 * @param {array} instances Reducer data for student range instances
 * @param {string} studentID Student ID
 */
export const renderRangeInstantiationButtonInputs = (lab, rules, instances, studentID,) => {
    // Try to find a specific rule for this student...
    let applicableRule = rules?.find((range) => lab.name.toLowerCase().startsWith(range.mylabs_part) &&
                range.account_id?.toString() === studentID?.toString());

    // If no specific rule exists, fall-back to a general rule comparison...
    if (typeof applicableRule === 'undefined') {
        applicableRule = rules?.find((range) =>
            lab.name.toLowerCase().startsWith(range.mylabs_part));
    }

    let studentRanges = instances?.filter((range) => {
        const courseMatch = lab.name.toLowerCase().startsWith(range.mylabs_part);
        const idMatch = range.account_id.toString() === studentID?.toString();

        return courseMatch && idMatch;
    });

    if (typeof studentRanges === 'undefined') {
        studentRanges = [];
    }

    const activeStudentRange = studentRanges?.find((range) => new Date(range.expires_at) > new Date(Date.now()));

    return {
        applicableRule,
        studentRanges,
        activeStudentRange,
    };
};
