import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './button.css';

/**
 * Render a button that handles the various states of the dynamically-provisioned workflow.
 *
 * @param {object} props
 * @param {?import ('../../../../types/typedefs').RangeInstance} props.range The active
 *  range for this button, if applicable.
 * @param {import ('../../../../types/typedefs').RangeRule} props.rule Data about the range definition.
 * @param {import ('../../../../types/typedefs').RangeInstance[]} props.ranges An array representing
 *  the entirety of the student's ranges.
 * @param {string} props.caption A string to override the automatically-determined caption.
 * @param {function} props.handleClick A function for handling "click" events.
 * @returns {JSX.Element}
 */
const Button = ({
    range,
    rule,
    ranges,
    caption,
    handleClick,
}) => {
    const rangeAllowanceExhausted = ranges.length >= rule.maximum_runs;
    const isProvisioning = range?.status === 'pending';

    // Calculate number of remaining extensions...
    const EXTENSION_DURATION_IN_DAYS = 2;
    const startDate = moment(range?.create_at);
    const maximumDate = moment(range?.expires_at).add(1, 'hours');
    const diffInDays = maximumDate.diff(startDate, 'd');
    const remaining = Math.ceil((rule.maximum_duration_in_days - diffInDays) / EXTENSION_DURATION_IN_DAYS);

    const [disabled, setDisabled] = useState(
        (rangeAllowanceExhausted && range?.status !== 'fulfilled') || isProvisioning || remaining === 0
    );

    const computeLabel = () => {
        if (range?.status === 'fulfilled') {
            let defaultHours = '48';

            if (remaining === 1 && (rule.maximum_duration_in_days - diffInDays) % EXTENSION_DURATION_IN_DAYS > 0) {
                defaultHours = '24';
            }

            return `Extend ${defaultHours} Hours`;
        } else if (rangeAllowanceExhausted) {
            return 'Range Allowance Exhausted';
        } else if (range?.status === 'pending') {
            return caption || 'Provisioning range...';
        }

        return 'Launch';
    };

    const computeIcon = (label) => {
        let name = 'fa fa-fw';

        if (label === 'Launch') {
            name += ' fa-external-link';
        } else if (label.includes('Extend')) {
            name += ' fa-arrow-circle-o-right';
        } else if (label.includes('Provisioning range')) {
            name += ' fa-spinner fa-pulse';
        } else if (label.includes('has been provisioned')) {
            name += ' fa-check';
        } else {
            name += ' fa-ban';
        }

        return name;
    };

    const click = async () => {
        setDisabled(true);

        const result = await handleClick();

        if (result !== null && typeof result !== 'undefined') {
            const rangeExtensionsExhausted = moment(result.expires_at).diff(result.create_at, 'd') >=
                rule.maximum_duration_in_days;

            if (result?.status === 'fulfilled' && !rangeExtensionsExhausted) {
                setDisabled(false);
            }
        }
    };

    useEffect(() => {
        setDisabled(
            (rangeAllowanceExhausted && range?.status !== 'fulfilled') || isProvisioning || remaining === 0
        );
    }, [range]);

    return (
        <button
            key={ rule.mylabs_part }
            className="sans-btn"
            disabled={ disabled }
            onClick={ click }
            data-testid="provision-button"
        >
            <span
                className={ computeIcon(computeLabel()) }
                style={ { padding: 0, lineHeight: '24px', marginRight: '6px' } }>
            </span>
            { computeLabel() }
        </button>
    );
};

Button.propTypes = {
    /**
     * The object that holds the range provisioning request. This range may be empty (`null`)
     * or may have contain an object with `status` property.
     */
    range: PropTypes.object,

    /** The range provisioning definition. */
    rule: PropTypes.object,

    /** An array of ranges. */
    ranges: PropTypes.arrayOf(PropTypes.object),

    /** A callback for handling the click event on the button. */
    handleClick: PropTypes.func,

    /**
     * The caption for the button, should the computed one need to be overridden. This only applies
     * when the range status is `pending`, to handle changing a changing stopwatch caption. */
    caption: PropTypes.string,
};

export default Button;
