import { createSlice } from '@reduxjs/toolkit';

/**
 * A payload from the Mylabs Files API
 * @typedef FilesResponsePayload
 * @type {Object.<string, import('../types/typedefs').LabFiles>}
 */

/**
 * @typedef {{files: Object.<string,FilesResponsePayload>|{}}} FilesSliceState
 */

/** @type {FilesSliceState} */
const INITAL_FILES_STATE = {
    files: {},
};

export const filesSlice = createSlice({
    name: 'files',
    initialState: INITAL_FILES_STATE,
    reducers: {
        /**
         * @param {FilesSliceState} state
         * @param {{payload: Object<string,FilesResponsePayload>}} action
         */
        setFiles: (state, action) => {
            const { payload } = action;
            const transformedPayload = {};

            Object.keys(payload).forEach((courseCatalog) => {
                Object.keys(payload[courseCatalog]).forEach(
                    (fileResponseIdentifier) => {
                        const labFiles =
                            payload[courseCatalog][fileResponseIdentifier];
                        const labInstance = labFiles.labName;

                        if (labFiles.metadata.length > 0) {
                            // If for some reason instance is a number, we need to convert it to str
                            if (labFiles.instance.toString().startsWith('jit-')) {
                                // If the lab files are for a range...
                                // Create the entry if one does not yet exist
                                if (!transformedPayload[labInstance]) {
                                    transformedPayload[labInstance] = { metadata: [] };
                                }
                                //append the metadata to the existing entry
                                transformedPayload[labInstance].metadata =
                                    transformedPayload[
                                        labInstance
                                    ].metadata.concat(labFiles.metadata);
                            } else {
                                // If we have metadata for this entry already (ie, a range was processed
                                // before other files), set the metadata aside and re-concat to be safe
                                let oldMeta = [];

                                if (transformedPayload[labInstance] && transformedPayload[labInstance].metadata) {
                                    oldMeta = transformedPayload[labInstance].metadata;
                                }
                                transformedPayload[labInstance] = labFiles;
                                transformedPayload[labInstance].metadata.concat(oldMeta);
                            }
                        }
                    }
                );
            });
            state.files = transformedPayload;
        },
    },
});

export const { setFiles } = filesSlice.actions;

export default filesSlice.reducer;
